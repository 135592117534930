import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { Theme } from '@mui/material';
import { alpha } from '@mui/system';
import { Tokens } from './designTokens';

export const cssMixins = {
  bgImageCoverNoRepeat: (url?: string): SerializedStyles => css`
    background-image: url(${url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `,
  centerHFixed: css`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  `,
  centerVAbsolute: css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `,
  colCentered: css`
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  colHCentered: css`
    flex-direction: column;
    display: flex;
    justify-content: center;
  `,
  colStretch: css`
    display: flex;
    flex-direction: column;
  `,
  colVCentered: css`
    flex-direction: column;
    align-items: center;
    display: flex;
  `,
  containerFullHeightInner: css`
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  `,
  containerFullHeightOuter: css`
    height: 100%;
  `,
  fade: (color: string): SerializedStyles => css`
    content: ' ';
    pointer-events: none;
    display: block;
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, ${alpha(color, 0)} 0%, ${color} 100%);
    z-index: -1;
  `,
  fillParent: css`
    width: 100%;
    height: 100%;
  `,
  footer: (theme: Theme): SerializedStyles => css`
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    position: sticky;
    bottom: 0;
    z-index: ${theme.zIndex.appBar};
  `,
  footerSolidFade: (color: string): SerializedStyles => css`
    background-color: ${color};
    box-shadow: 0 0 24px 28px ${color};
  `,
  global: css`
    html,
    body,
    #___gatsby,
    #gatsby-focus-wrapper,
    #root {
      height: 100%;
    }
    dl,
    dt,
    dd {
      margin: 0;
    }
  `,
  gradientTextWhite145: css`
    background: linear-gradient(
      145deg,
      ${Tokens.BrandColours.Greyscale.White.value} 0%,
      ${alpha(Tokens.BrandColours.Greyscale.White.value, 0.1)} 105%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  `,

  gradientTextWhite180: css`
    background: linear-gradient(
      180deg,
      ${alpha(Tokens.BrandColours.Greyscale.White.value, 0.4)} 0%,
      ${Tokens.BrandColours.Greyscale.White.value} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  `,
  rowCentered: css`
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  rowHCentered: css`
    display: flex;
    justify-content: center;
  `,
  rowStretch: css`
    display: flex;
  `,
  rowVCentered: css`
    align-items: center;
    display: flex;
  `,
  stretchAll: css`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  stretchBottom: css`
    right: 0;
    bottom: 0;
    left: 0;
  `,
  stretchLeft: css`
    top: 0;
    left: 0;
    bottom: 0;
  `,
  stretchRight: css`
    top: 0;
    right: 0;
    bottom: 0;
  `,
  stretchTop: css`
    top: 0;
    right: 0;
    left: 0;
  `,
  stretchX: css`
    right: 0;
    left: 0;
  `,
  strong: css`
    font-weight: 700;
  `,
  topRight: css`
    top: 0;
    right: 0;
  `,
};

export type TpCssMixins = typeof cssMixins;
